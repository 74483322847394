<template>
  <component
    :is="headerType"
    v-bind="componentProps"
    v-on="eventHandlers"
  >
    {{ label }}
  </component>
</template>

<script>
export default {
  name: 'HeaderTitle',
  props: {
    headerType: {
      type: String,
      default: 'h2',
    },
    label: {
      type: String,
      default: '暂无数据',
    },
    componentProps: {
      type: Object,
      default: Object,
    },
    eventHandlers: {
      type: Object,
      default: Object,
    },
  },
}
</script>

<style scoped>

</style>
