<template>
  <v-autocomplete
    v-model="dataModelComputed"
    :items="items"
    :search-input.sync="searchInput"
    color="blue-grey lighten-2"
    no-data-text="暂无数据"
    :placeholder="placeholder || label"
    :label="label"
    multiple
    filled
    chips
    v-bind="componentProps"
    v-on="eventHandlers"
    @input="searchInput = ''"
  >
    <template #selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data)"
      >
        <v-avatar
          v-if="showAvatar"
          left
          color="primary"
          class="v-avatar-light-bg primary--text"
        >
          <v-img
            v-if="data.item[avatarKey]"
            :src="data.item[avatarKey]"
            position="center center"
            :eager="true"
            alt="无"
          ></v-img>
          <v-icon
            v-else
            color="primary"
            size="28"
          >
            mdi-account-outline
          </v-icon>
        </v-avatar>
        {{ data.item[titleKey] }}
      </v-chip>
    </template>
    <template #item="data">
      <v-list-item-avatar
        v-if="showAvatar"
        left
        size="35"
        color="primary"
        class="v-avatar-light-bg primary--text"
      >
        <v-img
          v-if="data.item[avatarKey]"
          :src="data.item[avatarKey]"
          position="center center"
          :eager="true"
          alt="无"
        ></v-img>
        <v-avatar
          v-else
          color="primary"
          size="35"
        >
          <span class="white--text">{{ data.item[titleKey] ? data.item[titleKey].substr(data.item.name.length - 2) : '未知' }}</span>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ data.item[titleKey] }}</v-list-item-title>
        <v-list-item-subtitle
          v-show="data.item[subTitleKey]"
        >
          {{ data.item[subTitleKey] }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'AutocompleteChip',
  model: {
    prop: 'dataModel',
    event: 'update:data-model',
  },
  props: {
    dataModel: {
      type: [Object, Array],
      default: Array,
    },
    items: {
      type: Array,
      default: Array,
    },
    label: {
      type: String,
      default: '请输入',
    },
    placeholder: {
      type: String,
      default: '',
    },
    avatarKey: {
      type: String,
      default: 'avatar',
    },
    titleKey: {
      type: String,
      default: 'title',
    },
    subTitleKey: {
      type: String,
      default: '',
    },
    showAvatar: {
      type: [Boolean, Number],
      default: true,
    },
    componentProps: {
      type: Object,
      default: Object,
    },
    eventHandlers: {
      type: Object,
      default: Object,
    },
  },
  setup(props, { emit }) {
    const dataModelComputed = computed({
      get: () => props.dataModel,
      set: val => {
        console.log(val)

        emit('update:data-model', val)
      },
    })

    const searchInput = ref('')

    const remove = item => {
      // console.log(item)
      dataModelComputed.value.splice(item.index, 1)
    }

    return {
      dataModelComputed,
      searchInput,
      remove,
    }
  },
}
</script>

<style scoped>

</style>
