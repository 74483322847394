<template>
  <v-row :class="rowClass">
    <template
      v-for="(inputItem, index) in inputGroupList"
    >
      <v-col
        v-if="!getData(inputItem.hidden, inputGroupItem)"
        :key="`${inputItem}-${index}`"
        :class="getDataWithKey(inputItem.colClass)"
        cols="12"
        :md="getDataWithKey(inputItem.mdCols)"
      >
        <div>
          <component
            :is="getDataWithKey(inputItem.componentName)"
            v-model.trim="inputGroupModelCmp[getDataWithKey(inputItem.VModelKey)]"
            :class="getDataWithKey(inputItem.class)"
            :style="getDataWithKey(inputItem.style)"
            :label="getDataWithKey(inputItem.label)"
            :placeholder="getDataWithKey(inputItem.placeholder) || getDataWithKey(inputItem.label)"
            :disabled="getDataWithKey(inputItem.disabled)"
            :clearable="!getDataWithKey(inputItem.required)"
            :items="getDataWithKey(inputItem.items, getDataWithKey(inputItem.itemsKey))"
            :rules="getDataWithKey(inputItem.rules)"
            no-data-text="暂无数据"
            hide-details="auto"
            outlined
            dense
            v-bind="inputItem.componentProps"
            v-on="inputItem.eventHandlers"
          ></component>
        </div>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { computed } from '@vue/composition-api'
import { getDataWithKey, getData } from '@core/utils/dataProcess'
import {
  VTextField, VAutocomplete, VSelect, VTextarea, VSwitch, VCombobox, VDivider,
} from 'vuetify/lib/components'
import TextFieldDataPicker from '@/views/components/form-components/date-picker/TextFieldDataPicker.vue'
import AutocompleteChip from '@/views/components/form-components/autocomplete/AutocompleteChip.vue'
import BtnToggleGroup from '@/views/components/form-components/btn-toggle-group/BtnToggleGroup.vue'
import IconBtnGroup from '@/views/components/common/icon-btn-group/IconBtnGroup.vue'
import RadioGroup from '@/views/components/form-components/radio-group/RadioGroup.vue'
import BtnGroup from '@/views/components/common/btn-group/BtnGroup.vue'
import DivLabel from '@/views/components/form-components/div-label/DivLabel.vue'
import HeaderTitle from '@/views/components/common/headerTitle/HeaderTitle.vue'
import DivLabelText from '@/views/components/form-components/div-label/DivLabelText.vue'
import DateTimePicker from '@/views/components/form-components/date-time-picker/DateTimePicker.vue'
import FileInputSingle from '@/views/components/form-components/file-input/FileInputSingle.vue'

export default {
  name: 'InputGroup',
  components: {
    VSwitch,
    VSelect,
    VDivider,
    VCombobox,
    VTextarea,
    VTextField,
    VAutocomplete,
    AutocompleteChip,
    TextFieldDataPicker,
    FileInputSingle,
    BtnToggleGroup,
    DateTimePicker,
    DivLabelText,
    IconBtnGroup,
    HeaderTitle,
    RadioGroup,
    BtnGroup,
    DivLabel,
  },
  model: {
    prop: 'inputGroupModel',
    event: 'update:input-group-model',
  },
  props: {
    inputGroupModel: {
      type: Object,
      default: Object,
    },
    inputGroupList: {
      type: Array,
      default: Array,
    },
    inputGroupItem: {
      type: Object,
      default: Object,
    },
    rowClass: {
      type: [Object, String],
      default: 'ma-0 px-2',
    },
  },
  setup(props, { emit }) {
    const inputGroupModelCmp = computed({
      get: () => props.inputGroupModel,
      set: val => emit('update:input-group-model', val),
    })

    return {
      inputGroupModelCmp,
      getData,
      getDataWithKey,
    }
  },
}
</script>

<style scoped>

</style>
