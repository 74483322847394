<template>
  <div>
    <div>
      <btn-toggle-group
        v-model="pageData.authType"
        class="mt-1"
        :btn-toggle-props="{ mandatory: true, }"
        :btn-list="[{ label: '法人认证' }]"
      ></btn-toggle-group>
    </div>

    <div
      class="my-2 py-2"
    >
      <div>
        <h4>
          当前认证组织：{{ submitData.enterpriseName }}
        </h4>

        <div>
          请进行法人本人意愿确认即可完成企业实名认证
        </div>
      </div>
    </div>

    <div
      class="mt-8"
    >
      <h4>
        法定代表人身份认证方式
      </h4>

      <div
        class="d-flex"
      >
        <v-card
          class="cursorHoverPointer mb-8 pe-8 py-2"
          elevation="4"
        >
          <div
            class="mb-2"
          >
            <h3>
              人脸认证
            </h3>

            <div>
              需法定代表人本人进行活体刷脸完成认证
            </div>
          </div>

          <svg-content
            :svg-src="require('@/assets/images/svg/alipay.svg')"
            title="支付宝"
            content="需要唤起支付宝APP完成人脸识别"
          ></svg-content>
        </v-card>
      </div>
    </div>

    <div>
      <div
        class="my-2"
      >
        法定代表人实名信息
      </div>

      <div>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="submitData.contractRealName"
              label="姓名"
              placeholder="姓名"
              hide-details="auto"
              readonly
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="submitData.contractIdCard"
              label="身份证号"
              placeholder="身份证号"
              hide-details="auto"
              readonly
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </div>

    <div
      class="d-flex"
    >
      <v-checkbox
        v-model="pageData.agreeAuthChecked"
        :true-value="true"
        :false-value="false"
        :rules="[trueValidator]"
        hide-details="auto"
      >
        <template #label>
          我同意 <span class="checkboxHighlightTextColor">小五兄弟</span> 采集人脸识别数据用于认证服务
        </template>
      </v-checkbox>
    </div>
  </div>
</template>

<script>
import { trueValidator } from '@core/utils/validation'
import SvgContent from '@/views/project/real-name-auth/SvgContent.vue'
import BtnToggleGroup from '@/views/components/form-components/btn-toggle-group/BtnToggleGroup.vue'

export default {
  name: 'LegalPersonAuth',
  components: {
    SvgContent,
    BtnToggleGroup,
  },
  props: {
    pageData: {
      type: Object,
      default: Object,
    },
    submitData: {
      type: Object,
      default: Object,
    },
  },
  setup() {
    return {
      trueValidator,
    }
  },
}
</script>

<style scoped>

</style>
