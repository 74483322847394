<template>
  <div>
    <q-r-code-dialog
      v-model="pageData.isQRWindowOpen"
      :qrcode-src="pageData.QRCodeSrc"
    ></q-r-code-dialog>

    <privacy-policy
      v-model="pageData.isPrivacyPolicyDialogOpen"
    ></privacy-policy>

    <v-card>
      <v-card-title>
        {{ pageData.title }}
      </v-card-title>

      <v-card-text>
        <v-stepper
          v-model="pageData.step"
          class="transparent custom-header pb-0"
        >
          <v-stepper-header>
            <v-stepper-step
              :complete="pageData.step > 1"
              step="1"
            >
              组织信息核验
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="pageData.step > 2"
              step="2"
            >
              认证方式
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">
              认证结果
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form
                ref="companyAuthFormStep1"
                v-model="pageData.formValid[0]"
                @submit.prevent="onSubmitStep1"
              >
                <input-group
                  v-model="submitData"
                  :input-group-list="step1FormComponents"
                ></input-group>

                <div
                  class="d-flex ms-2"
                >
                  <v-checkbox
                    v-model="pageData.privacyPolicyChecked"
                    :true-value="true"
                    :false-value="false"
                    :rules="[trueValidator]"
                    hide-details="auto"
                  >
                    <template #label>
                      我已阅读并同意
                      <span
                        style="color: #6C5C99"
                        @click="pageData.isPrivacyPolicyDialogOpen = true"
                      >
                        《隐私政策》
                      </span>
                    </template>
                  </v-checkbox>
                </div>

                <btn-group
                  :btn-group-list="step1BtnGroup"
                  div-class="d-flex justify-end mt-2"
                ></btn-group>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-form
                ref="companyAuthFormStep2"
                v-model="pageData.formValid[1]"
                @submit.prevent="onSubmitStep2"
              >
                <LegalPersonAuth
                  v-if="pageData.type === 1"
                  :page-data="pageData"
                  :submit-data="submitData"
                ></LegalPersonAuth>

                <LegalPersonAuthedAuth
                  v-if="pageData.type === 2"
                  :page-data="pageData"
                  :submit-data="submitData"
                ></LegalPersonAuthedAuth>

                <btn-group
                  :btn-group-list="step2BtnGroup"
                  div-class="d-flex justify-end mt-2"
                ></btn-group>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-form
                ref="companyAuthFormStep3"
                v-model="pageData.formValid[2]"
                @submit.prevent="onSubmitStep3"
              >
                <div
                  class="d-flex flex-column justify-center align-center"
                >
                  <v-icon
                    size="64"
                  >
                    mdi-cog
                  </v-icon>

                  <div
                    class="font-weight-bold my-6"
                  >
                    请耐心等待授权书审核通过即可完成认证
                  </div>

                  <div
                    class="mb-6"
                  >
                    预计1-3工作日完成
                  </div>

                  <v-btn
                    @click="refreshUserInfo"
                  >
                    查询结果
                  </v-btn>
                </div>
              </v-form>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { useRouter } from '@core/utils'
import { computed, reactive, ref } from '@vue/composition-api'
import { modalEnquireWait, toastFail, toastSuccess } from '@core/utils/prompt'
import { requestGet, requestPost } from '@core/utils/form'
import { localUserData, refreshUserInfo, userInfo } from '@core/utils/userData'
import { required, trueValidator } from '@core/utils/validation'
import BtnGroup from '@/views/components/common/btn-group/BtnGroup.vue'
import QRCodeDialog from '@/views/project/real-name-auth/QRCodeDialog.vue'
import PrivacyPolicy from '@/views/project/real-name-auth/PrivacyPolicy.vue'
import InputGroup from '@/views/components/common/input-group/InputGroup.vue'
import LegalPersonAuth from '@/views/project/real-name-auth/LegalPersonAuth.vue'
import LegalPersonAuthedAuth from '@/views/project/real-name-auth/LegalPersonAuthedAuth.vue'

export default {
  name: 'CompanyAuth',
  components: {
    BtnGroup,
    InputGroup,
    QRCodeDialog,
    PrivacyPolicy,
    LegalPersonAuth,
    LegalPersonAuthedAuth,
  },
  setup() {
    const companyAuthFormStep1 = ref(null)
    const companyAuthFormStep2 = ref(null)
    const companyAuthFormStep3 = ref(null)
    const pageData = reactive({
      title: '企业实名认证',
      QRCodeSrc: '企业实名认证',
      isQRWindowOpen: false,
      privacyPolicyChecked: false,
      isPrivacyPolicyDialogOpen: false,
      formValid: [null, null, null],
      step: 1,
      type: 0,
    })

    const { router } = useRouter()

    const routerBack = () => {
      router.back()
    }

    const submitDataOri = {
      ...JSON.parse(JSON.stringify(localUserData)),
      ...JSON.parse(JSON.stringify(userInfo)),
      authorizationFileDetail: {},
      authorizationFile: '',
    }

    const submitData = ref(JSON.parse(JSON.stringify(submitDataOri)))
    console.log(submitData.value)

    const onSubmitStep1 = () => {
      if (pageData.formValid[0]) {
        modalEnquireWait(() => requestGet('/authentic/getCompanyType', {
          realName: submitData.value.realName, memberId: userInfo.id, enterpriseName: userInfo.enterpriseName, businessLicenseCard: userInfo.idCard,
        }))
          .then(res => {
            if (res.isConfirmed) {
              console.log(res)
              pageData.type = res.value.data?.type
              if (pageData.type) pageData.step += 1
              else toastFail('认证信息获取失败！')
            }
          })
      } else companyAuthFormStep1.value.validate()
    }

    const onSubmitStep2 = () => {
      console.log(submitData.value)
      if (pageData.formValid[1]) {
        // 2是法人认证，1是法人授权认证
        const submitDataCopy = JSON.parse(JSON.stringify(submitData.value))
        if (pageData.type === 1) {
          requestGet('/authentic/getIdentifyUrl', {
            memberId: submitDataCopy.id,
          }).then(res => {
            console.log(res)
            pageData.QRCodeSrc = res.data.identifyUrl
            pageData.serialNo = res.data.serialNo
            pageData.isQRWindowOpen = true
          })

          return
        }
        submitDataCopy.authorizationFile = submitDataCopy.authorizationFileDetail.key
        requestPost('/authentic/updateAuthorizationFile', {
          memberId: submitDataCopy.id,
          authorizationFile: submitDataCopy.authorizationFile,
          realName: submitDataCopy.realName,
        }).then(res => {
          pageData.step += 1
          toastSuccess(res.data.message)
        })
      } else {
        companyAuthFormStep2.value.validate()
      }
    }

    const onSubmitStep3 = () => {
      if (pageData.formValid[2]) {
        console.log(submitData.value)
      } else {
        companyAuthFormStep3.value.validate()
      }
    }

    // 表单相关组件
    const step1FormComponents = reactive([
      {
        VModelKey: 'name',
        componentName: 'VTextField',
        colClass: 'mx-0 px-0',
        label: '企业名称',
        required: true,
        rules: [required],
        disabled: true,
        componentProps: {
          hint: '若营业执照上企业名称为空或*，此处请输入法定代表人姓名',
        },
      },
      {
        VModelKey: 'idCard',
        componentName: 'VTextField',
        colClass: 'mx-0 px-0',
        label: '统一社会信用代码/工商注册号',
        rules: [required],
        required: true,
        disabled: true,
      },
      {
        VModelKey: 'realName',
        componentName: 'VTextField',
        colClass: 'mx-0 px-0',
        label: '法定代表人/负责人',
        required: true,
        rules: [required],
        componentProps: {
          hint: '合伙企业输入执行事务合伙人名称， 多法人企业输入多法人姓名，如：张三、李四',
        },
      },
    ])

    const step1BtnGroup = reactive([
      {
        text: '返回', color: 'primary', eventHandlers: { click: routerBack },
      },
      {
        text: '同意并继续', color: 'success', class: 'mx-2', componentProps: { type: 'submit' },
      },
    ])

    const step2BtnGroup = reactive([
      {
        text: '返回',
        color: 'primary',
        eventHandlers: {
          click: () => {
            pageData.step -= 1
          },
        },
      },
      {
        hidden: computed(() => !pageData.type || pageData.type !== 1), text: '同意并继续', color: 'success', class: 'mx-2', componentProps: { type: 'submit' },
      },
      {
        hidden: computed(() => !pageData.type || pageData.type !== 2), text: '确认', color: 'success', class: 'mx-2', componentProps: { type: 'submit' },
      },
    ])

    return {
      pageData,
      submitData,
      step1FormComponents,
      step1BtnGroup,
      step2BtnGroup,
      companyAuthFormStep1,
      companyAuthFormStep2,
      companyAuthFormStep3,
      routerBack,
      onSubmitStep1,
      onSubmitStep2,
      onSubmitStep3,

      trueValidator,
      refreshUserInfo,
    }
  },
}
</script>

<style scoped>

</style>
