<template>
  <component
    :is="`v-radio-group`"
    v-if="!getDataWithKey(radioGroupProps.hidden, 'value')"
    v-model="data"
    :disabled="getDataWithKey(disabled)"
    :rules="rules"
    :row="row"
    hide-details="auto"
    v-bind="radioGroupProps.componentProps"
    v-on="radioGroupProps.eventHandlers"
  >
    {{ getDataWithKey(label, 'value') }}
    <component
      :is="`v-radio`"
      v-for="(item, index) in radioList"
      :key="`${item.value}-${index}`"
      :label="item.label"
      :value="item.value"
      v-bind="item.componentProps"
      v-on="item.eventHandlers"
    ></component>
    {{ getDataWithKey(remark, 'value') }}
  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import { getDataWithKey } from '@core/utils/dataProcess'
import { VRadioGroup, VRadio } from 'vuetify/lib/components'

export default {
  name: 'RadioGroup',
  components: {
    VRadioGroup,
    VRadio,
  },
  model: {
    prop: 'dataModel',
    event: 'update:data-model',
  },
  props: {
    dataModel: {
      type: [Boolean, Number, String, Object, Array, null],
      default: 0,
    },
    label: {
      type: String,
      default: '请选择：',
    },
    remark: {
      type: String,
      default: '',
    },
    row: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: [Boolean, String, Object],
      default: false,
    },
    rules: {
      type: Array,
      default: Array,
    },
    radioGroupProps: {
      type: Object,
      default: Object,
    },
    radioList: {
      type: Array,
      default: Array,
    },
  },
  setup(props, { emit }) {
    const data = computed({
      get: () => props.dataModel,
      set: val => emit('update:data-model', val),
    })

    return {
      data,
      getDataWithKey,
    }
  },
}
</script>

<style scoped>

</style>
