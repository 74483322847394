<template>
  <div>
    <div>
      <btn-toggle-group
        v-model="pageData.authType"
        class="mt-1"
        :btn-toggle-props="{ mandatory: true, }"
        :btn-list="[{ label: '法人授权认证' }]"
      ></btn-toggle-group>
    </div>

    <div
      class="my-2 py-2"
    >
      <div>
        <h4>
          当前认证组织：{{ submitData.enterpriseName }}
        </h4>

        <div>
          根据当前组织机构信息自动生成一份授权书，授权书模板打印加盖实体公章上传审核通过后可完成实名认证。
          <a @click="previewAuthTemplate">查看授权书</a>
        </div>
      </div>
    </div>

    <div
      class="d-flex"
    >
      <v-card
        class="ma-1 px-3 pt-3 pb-6"
        elevation="4"
      >
        <h4>
          上传授权书
        </h4>

        <div>
          <div
            class="my-1"
          >
            <span class="font-weight-bold">步骤一：</span>
            下载授权书，确认授权书内容
          </div>

          <v-btn
            @click="downloadAuthTemplate"
          >
            <v-icon>
              mdi-export
            </v-icon>
            下载授权书模板
          </v-btn>
        </div>

        <div
          class="my-4"
        >
          <div
            class="my-3"
          >
            <span class="font-weight-bold">步骤二：</span>
            必须用下载的授权书模板打印后加盖实体公章上传
          </div>

          <FileInputSingle
            v-model="submitData.authorizationFileDetail"
            label="点击上传授权书"
            accept-type=".jpg,.jpeg,.png,.pdf"
            is-required
          ></FileInputSingle>

          <div
            class="my-2"
          >
            仅支持jpg、jpeg、png、pdf格式，大小限制10M
          </div>
        </div>
      </v-card>
    </div>

    <!--    <div-->
    <!--      class="d-flex"-->
    <!--    >-->
    <!--      <v-checkbox-->
    <!--        v-model="pageData.agreeAuthChecked"-->
    <!--        :true-value="true"-->
    <!--        :false-value="false"-->
    <!--        :rules="[trueValidator]"-->
    <!--        hide-details="auto"-->
    <!--      >-->
    <!--        <template #label>-->
    <!--          我同意 <span class="checkboxHighlightTextColor">小五兄弟</span> 采集人脸识别数据用于认证服务-->
    <!--        </template>-->
    <!--      </v-checkbox>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { trueValidator } from '@core/utils/validation'
import { exportFile } from '@core/utils/form'
import { computed } from '@vue/composition-api'
import FileInputSingle from '@/views/components/form-components/file-input/FileInputSingle.vue'
import BtnToggleGroup from '@/views/components/form-components/btn-toggle-group/BtnToggleGroup.vue'

export default {
  name: 'LegalPersonAuth',
  components: {
    BtnToggleGroup,
    FileInputSingle,
  },
  props: {
    pageData: {
      type: Object,
      default: Object,
    },
    submitData: {
      type: Object,
      default: Object,
    },
  },
  setup(props, { emit }) {
    const submitDataCmp = computed({
      get: () => props.submitData,
      set: val => emit('update:submit-data', val),
    })
    const downloadAuthTemplate = () => {
      exportFile('/authentic/download', '下载的 授权书模板', {
        realName: submitDataCmp.value.realName, memberId: submitDataCmp.value.id, enterpriseName: submitDataCmp.value.enterpriseName, businessLicenseCard: submitDataCmp.value.idCard,
      }, { type: 'pdf' })
    }
    const previewAuthTemplate = () => {
      exportFile('/authentic/download', '下载的 授权书模板', {
        realName: submitDataCmp.value.realName, memberId: submitDataCmp.value.id, enterpriseName: submitDataCmp.value.enterpriseName, businessLicenseCard: submitDataCmp.value.idCard,
      }, { type: 'pdf', preview: true })
    }

    return {
      previewAuthTemplate,
      downloadAuthTemplate,
      trueValidator,
    }
  },
}
</script>

<style scoped>

</style>
