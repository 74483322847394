<template>
  <component
    :is="`div`"
    v-bind="componentProps"
    v-on="eventHandlers"
  >
    {{ getDataWithKey(label) }}
  </component>
</template>

<script>
import { getDataWithKey } from '@core/utils/dataProcess'

export default {
  name: 'DivLabel',
  props: {
    label: {
      type: String,
      required: false,
      default: '暂无数据',
    },
    componentProps: {
      type: Object,
      default: Object,
    },
    eventHandlers: {
      type: Object,
      default: Object,
    },
  },
  setup() {
    return {
      getDataWithKey,
    }
  },
}
</script>

<style scoped>

</style>
