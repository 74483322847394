<template>
  <component
    :is="`v-tooltip`"
    :top="top"
    :bottom="bottom"
    :right="right"
    :left="left"
    :color="color"
    :disabled="disabled"
    v-bind="componentProps"
    v-on="eventHandlers"
  >
    <template #activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
      >
        <slot></slot>
      </div>
    </template>
    <span class="tipClass">{{ title }}</span>
  </component>
</template>

<script>
import { VTooltip } from 'vuetify/lib/components'

export default {
  name: 'TooltipBase',
  components: {
    VTooltip,
  },
  props: {
    title: {
      type: String,
      default: '点我',
    },
    tipClass: {
      type: String,
      default: '',
    },
    top: {
      type: Boolean,
      default: true,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    componentProps: {
      type: Object,
      default: Object,
    },
    eventHandlers: {
      type: Object,
      default: Object,
    },
  },
  setup() {
  },
}
</script>

<style scoped>

</style>
