<template>
  <div>
    <v-row
      class="me-1"
    >
      <v-col
        v-if="preview && (isPreviewFile() || getDataWithKey(defaultPreviewImg))"
        class="py-1 ps-6 d-flex justify-center"
        cols="12"
        md="2"
      >
        <div
          v-if="fileInputData.key ? isPreviewFile() : getDataWithKey(defaultPreviewImg)"
          class="previewContainer"
        >
          <img
            class="previewImg"
            :src="fileInputData.key ? imgSrc : getDataWithKey(defaultPreviewImg)"
            alt="暂无图片"
          />

          <div
            v-if="!hidePreviewDiv"
            class="previewDiv"
            :style="previewDivStyle"
          >
            图片预览
            <img
              class="previewHoverImg"
              :src="fileInputData.key ? imgSrc : getDataWithKey(defaultPreviewImg)"
              :style="previewImgStyle"
              alt="暂无图片"
            />
          </div>
        </div>

        <div
          v-else
        >
          暂无预览
        </div>
      </v-col>

      <v-col
        cols="12"
        :md="preview && (isPreviewFile() || getDataWithKey(defaultPreviewImg)) ? 10 : 12"
        class="py-2 px-2"
      >
        <v-row>
          <v-col
            cols="9"
            :md="inputCols"
            class="pr-0"
          >
            <v-file-input
              v-model="file"
              name="file"
              :label="getDataWithKey(label)"
              :rules="getDataWithKey(isRequired) ? [fileInputData.key ? true : '这是必选项'] : [!file || fileInputData.key ? true : '已选择文件，请先上传或清空文件']"
              :accept="getDataWithKey(acceptType)"
              :disabled="getDataWithKey(isDisabled)"
              :prepend-icon="prependIcon"
              hide-details="auto"
              show-size
              clearable
              outlined
              dense
              v-bind="componentProps"
            >
            </v-file-input>
          </v-col>
          <v-col
            v-if="!getDataWithKey(isDisabled)"
            cols="3"
            :md="12 - inputCols"
          >
            <div class="d-flex">
              <div
                v-if="!isShowProgressBar"
              >
                <TooltipBase
                  title="上传"
                >
                  <v-btn
                    color="success"
                    class="me-2"
                    outlined
                    icon
                    @click="uploadFile"
                  >
                    <v-icon>mdi-upload</v-icon>
                  </v-btn>
                </TooltipBase>
              </div>

              <div
                v-if="isShowProgressBar && !isPaused"
              >
                <TooltipBase
                  title="暂停"
                >
                  <v-btn
                    color="warning"
                    outlined
                    icon
                    class="me-2"
                    @click="uploadPause"
                  >
                    <v-icon>mdi-pause</v-icon>
                  </v-btn>
                </TooltipBase>
              </div>

              <div
                v-if="isShowProgressBar && isPaused"
              >
                <TooltipBase
                  title="继续"
                >
                  <v-btn
                    color="info"
                    outlined
                    icon
                    class="me-2"
                    @click="uploadResume"
                  >
                    <v-icon>mdi-restart</v-icon>
                  </v-btn>
                </TooltipBase>
              </div>

              <div
                v-if="isShowProgressBar"
              >
                <TooltipBase
                  title="取消"
                >
                  <v-btn
                    class="me-2"
                    color="error"
                    outlined
                    icon
                    @click="uploadCancel"
                  >
                    <v-icon>mdi-cancel</v-icon>
                  </v-btn>
                </TooltipBase>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-progress-linear
          v-if="isShowProgressBar"
          :value="progress.value"
          height="20"
          striped
          class="mt-2 mx-2 px-5"
        >
          {{ Math.ceil(progress.value) }}%
        </v-progress-linear>

        <div
          v-if="fileInputData.key"
        >
          <v-tooltip
            top
          >
            <template #activator="{ on, attrs }">
              <v-chip
                text-color="primary"
                color="primary"
                class="mt-2 mx-2"
                v-bind="attrs"
                v-on="on"
                @click="uploadFileDelete()"
              >
                {{ fileInputData.name }} {{ fileInputData.duration ? ` | 时长：${getTime(fileInputData.duration)}` : '' }}
              </v-chip>
            </template>
            <div>点击{{ isDisabled ? '下载' : '删除' }}该上传文件</div>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import {
  modalFail,
  toastFail, toastInfo, toastSuccess,
} from '@core/utils/prompt'
import { getDataWithKey, getDataWithKeySplit, getVideoData } from '@core/utils/dataProcess'
import { required } from '@core/utils/validation'
import { getTime } from '@core/utils/formatTime'
import { AliOSS } from '@core/utils/useAliOSS'
import TooltipBase from '@/views/components/common/tooltip-base/TooltipBase.vue'
import config from '../../../../../config'

export default {
  name: 'FileInputSingle',
  components: {
    TooltipBase,
  },
  model: {
    prop: 'fileInputModel',
    event: 'update:file-input-model',
  },
  props: {
    fileInputModel: {
      type: Object,
      default: Object,
    },
    acceptType: {
      type: [String, Object],
      default: '*',
    },
    inputCols: {
      type: Number,
      default: 9,
    },
    isRequired: {
      type: [Boolean, Object],
      default: false,
    },
    isDisabled: {
      type: [Boolean, Object],
      default: false,
    },
    canDownload: {
      type: Boolean,
      default: false,
    },
    label: {
      type: [String, Object],
      default: '请上传文件',
    },
    prependIcon: {
      type: String,
      default: '$file',
    },
    bucket: {
      type: String,
      default: config.defaultBucket,
    },
    uploadOssPath: {
      type: String,
      default: '',
    },
    preview: {
      type: [Boolean, Object],
      default: false,
    },
    componentProps: {
      type: Object,
      default: Object,
    },
    defaultPreviewImg: {
      type: [String, Object],
      default: '',
    },
    previewDivStyle: {
      type: [String, Object],
      default: '',
    },
    previewImgStyle: {
      type: [String, Object],
      default: '',
    },
    hidePreviewDiv: {
      type: Boolean,
      default: false,
    },
    userIdLocalstorageKey: {
      type: String,
      default: 'id',
    },
  },
  setup(props, { emit }) {
    const fileInputData = computed({
      get: () => props.fileInputModel,
      set: val => emit('update:file-input-model', val),
    })
    const imgSuffixList = ['.jpg', '.jpeg', '.png', '.svg']
    const isPreviewFile = () => imgSuffixList.some(item => fileInputData.value?.name?.endsWith(item))
    let aliOss = new AliOSS(props.bucket)
    const file = ref(null)
    const imgSrcRef = ref(null)
    const imgSrc = computed(() => (props.preview && isPreviewFile() ? imgSrcRef.value : ''))
    const isPaused = ref(false)
    const isUploaded = ref(false)
    const isShowProgressBar = ref(false)
    const progress = computed(() => aliOss.progressBar)
    const userId = getDataWithKeySplit(JSON.parse(localStorage.getItem('userData')), props.userIdLocalstorageKey)

    // aliOss.fetchOSS()
    aliOss.fetchOSS().then(() => {
      if (props.preview && isPreviewFile()) {
        imgSrcRef.value = aliOss.fetchDownloadLink(fileInputData.value.key, `图片预览-${new Date().valueOf()}`, false)
      }
    })

    /* 获取文件前缀 */
    function getPrefixPath() {
      const date = new Date()
      const getFormatDateMonth = () => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`
      const getFormatDate = () => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      const getFormatTime = () => `${date.getHours().toString().padStart(2, '0')}${date.getMinutes().toString().padStart(2, '0')}${date.getSeconds().toString().padStart(2, '0')}`

      return props.uploadOssPath ? `${props.uploadOssPath}/customer/${userId}/${getFormatDateMonth()}/${getFormatDate()}/${getFormatTime()}` : `customer/${getFormatDate()}/${userId}/${date.valueOf()}`
    }

    // 文件上传（覆盖同名文件）
    function fileUpload() {
      if (file.value) {
        const data = file.value

        // 先获取视频的时长、宽高等信息
        getVideoData(data).then(res => {
          const fileNameUpload = `${getPrefixPath()}_${data.name}`
          aliOss.fileUploadMulti(fileNameUpload, data).then(response => {
            console.log(response)

            /* 判断文件是否上传成功(阿里云上是否有同名文件) */
            aliOss.list(fileNameUpload).then(result => {
              if (result.objects.length) {
                /* 阿里云中存在同名文件（上传成功） */
                fileInputData.value.size = data.size
                fileInputData.value.name = data.name
                fileInputData.value.key = fileNameUpload
                fileInputData.value.bucket = props.bucket
                fileInputData.value.duration = res.duration || null
                fileInputData.value.videoHeight = res.videoHeight || null
                fileInputData.value.videoWidth = res.videoWidth || null
                toastSuccess(`${data.name} 文件上传完成`)

                imgSrcRef.value = props.preview && isPreviewFile() ? aliOss.fetchDownloadLink(fileInputData.value.key, `图片预览-${new Date()}`, false) : ''
                isShowProgressBar.value = false
                isPaused.value = false
                isUploaded.value = true
              } else {
                /* 在阿里云OSS中找不到对应文件，上传失败 */
                modalFail('文件上传失败，请重新上传！')
                isShowProgressBar.value = false
                isPaused.value = false
              }
            }).catch(e => {
              /* 查询阿里云OSS中对应文件失败，也认为上传失败 */
              console.log(e)
              modalFail('文件上传失败，请重新上传！')
              isShowProgressBar.value = false
              isPaused.value = false
            })
          }).catch(error => {
            console.log(error)

            // 取消上传
            if (error.name === 'cancel') return
            modalFail('上传失败，请重试!')
            isShowProgressBar.value = false
            isPaused.value = false
          })
        }).catch(err => {
          modalFail('获取文件信息失败，请重试！')
          console.log(err)
          isShowProgressBar.value = false
          isPaused.value = false
        })
      }
    }

    const uploadFile = () => {
      if (!file.value) {
        toastInfo('还未选择文件')

        return
      }

      if (props.acceptType && props.acceptType !== '*') {
        const suffix = `.${file.value.name.split('.').pop().toLowerCase()}`
        console.log(suffix)
        if (!props.acceptType.split(',').map(i => i.toLowerCase()).includes(suffix)) {
          toastInfo(`仅允许上传 ${props.acceptType} 后缀的文件!`)

          return
        }
      }

      toastSuccess('开始上传')
      console.log(file.value)
      isShowProgressBar.value = true
      fileUpload()
    }

    const uploadPause = () => {
      try {
        // 暂停上传。
        aliOss.fileUploadPause()
        toastInfo('暂停上传')
        isPaused.value = true
      } catch (e) {
        toastInfo('暂停上传失败，请重试')
        console.log(e)
      }
    }

    const uploadResume = () => {
      toastInfo('继续上传')
      isPaused.value = false
      fileUpload()
    }

    const uploadCancel = () => {
      // 取消上传。
      aliOss.fileUploadCancel().then(res => {
        toastFail('取消上传')
        console.log(res)
        isShowProgressBar.value = false
        isPaused.value = false
      }).catch(error => {
        toastFail('取消上传失败，请重试')
        console.log(error)
      })
    }

    const uploadFileDelete = () => {
      // fileDelete(filesUploaded.value[fileIndex].key).then(response => {
      //   console.log(response)
      // emit('update:file-input-model', {})
      if (props.isDisabled) {
        if (fileInputData.value.key) window.open(aliOss.fetchDownloadLink(fileInputData.value.key))
      } else {
        fileInputData.value = {}
        toastSuccess('成功删除文件')
        isUploaded.value = false
        isShowProgressBar.value = false
      }

      // }).catch(error => {
      //   console.log(error)
      //   modalFail('删除失败')
      // })
    }

    watch(() => props.bucket, () => {
      aliOss = new AliOSS(props.bucket)
      aliOss.fetchOSS()
    })

    watch(() => props.fileInputModel.key, () => {
      if (props.preview && isPreviewFile()) {
        if (!aliOss.client) {
          aliOss.fetchOSS().then(() => {
            imgSrcRef.value = aliOss.fetchDownloadLink(fileInputData.value.key, `图片预览-${new Date().valueOf()}`, false)
          })
        } else {
          imgSrcRef.value = aliOss.fetchDownloadLink(fileInputData.value.key, `图片预览-${new Date().valueOf()}`, false)
        }
      }
    })

    return {
      file,
      imgSrc,
      isPaused,
      progress,
      isUploaded,
      fileInputData,
      isShowProgressBar,
      uploadFileDelete,
      isPreviewFile,
      uploadResume,
      uploadCancel,
      uploadPause,
      uploadFile,

      getTime,
      getDataWithKey,

      // validator
      validator: { required },
    }
  },
}
</script>

<style scoped>
.previewContainer {
  position: relative;
}

.previewImg {
  max-width: 88px;
  max-height: 88px;
}

.previewDiv {
  position: absolute;
  overflow: visible;
  top: -20px;
  left: 0;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease, display 0.3s ease;
}

.previewContainer:hover .previewDiv {
  display: block;
  opacity: 1;
  z-index: 666;
}

.previewHoverImg {
  max-width: 40vw;
  max-height: 40vh;
}
</style>
