<template>
  <component
    :is="`div`"
    v-bind="componentProps"
    v-on="eventHandlers"
  >
    <span :class="labelClass">{{ getDataWithKey(label) }}</span>
    <span :class="textClass">{{ getDataWithKey(text) }}</span>
  </component>
</template>

<script>
import { getDataWithKey } from '@core/utils/dataProcess'

export default {
  name: 'DivLabelText',
  props: {
    label: {
      type: String,
      default: '暂无数据',
    },
    labelClass: {
      type: [String, Object],
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    textClass: {
      type: [String, Object],
      default: '',
    },
    componentProps: {
      type: Object,
      default: Object,
    },
    eventHandlers: {
      type: Object,
      default: Object,
    },
  },
  setup() {
    return {
      getDataWithKey,
    }
  },
}
</script>

<style scoped>

</style>
