// 用户输入任意秒数, 函数计算该毫秒数对应的时分秒, 并返回
// eslint-disable-next-line import/prefer-default-export
export const getTime = time => {
  if (!time) return time
  if (time.toString().includes(':')) return time
  try {
    // 转换为时分秒
    let h = parseInt(((time / 60) / 60) % 24, 10)
    h = h < 10 ? `0${h}` : h
    let m = parseInt((time / 60) % 60, 10)
    m = m < 10 ? `0${m}` : m
    let s = parseInt(time % 60, 10)
    s = s < 10 ? `0${s}` : s

    // 作为返回值返回
    let res = ''
    if (h !== '00') res += `${h}:`

    // if (m !== '00') res += `${m}:`
    res += `${m}:${s}`

    return res
  } catch (e) {
    return time
  }
}
