<template>
  <component
    :is="`v-menu`"
    v-model="menuActive"
    :close-on-content-click="true"
    transition="scale-transition"
    max-width="290px"
    min-width="auto"
    offset-y
    v-bind="menuProps.componentProps"
    v-on="menuProps.eventHandlers"
  >
    <template #activator="{ on, attrs }">
      <component
        :is="`v-text-field`"
        v-model="date"
        :label="label"
        :placeholder="label"
        :rules="rules"
        readonly
        outlined
        dense
        hide-details="auto"
        v-bind="extendProps(attrs, textFieldProps.componentProps)"
        v-on="extendProps(on, textFieldProps.eventHandlers)"
      ></component>
    </template>
    <component
      :is="`v-date-picker`"
      v-model="date"
      color="primary"
      locale="zh-cn"
      :type="type"
      no-title
      v-bind="datePickerProps.componentProps"
      v-on="datePickerProps.eventHandlers"
    >
    </component>
  </component>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { VDatePicker, VTextField, VMenu } from 'vuetify/lib/components'

export default {
  name: 'TextFieldDataPicker',
  components: {
    VMenu,
    VTextField,
    VDatePicker,
  },
  model: {
    prop: 'dateModel',
    event: 'update:date-model',
  },
  props: {
    dateModel: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '请选择日期',
    },
    type: {
      type: String,
      required: false,
      default: 'date',
    },
    rules: {
      type: Array,
      required: false,
      default: Array,
    },
    menuProps: {
      type: Object,
      required: false,
      default: Object,
    },
    datePickerProps: {
      type: Object,
      required: false,
      default: Object,
    },
    textFieldProps: {
      type: Object,
      required: false,
      default: Object,
    },
  },
  setup(props, { emit }) {
    const menuActive = ref(null)
    const extendProps = (origin, extend) => (extend ? { ...extend, ...origin } : origin)
    const date = computed({
      get: () => props.dateModel,
      set: v => {
        emit('update:date-model', v)
      },
    })

    return {
      date,
      menuActive,
      extendProps,
    }
  },
}
</script>

<style scoped>

</style>
